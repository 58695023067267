// Dependencies
import React, { useContext } from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import Link from "gatsby-link";
import Fade from "react-reveal";

// Components
import { Container } from "../index";
import SEO from "../../components/seo";
import { FilterSelectionContext } from "../../components/filterSelectionContext";
import { FilterSelectionValueContext } from "../../components/filterSelectionValueContext";
import CategoryList from "../../components/categoryList";
import TitleImage from "../../components/title-image";

interface Props {
  data: {
    allDataJson: {
      edges: {
        node: {
          arsenal: {
            titleImage: any;
          };
        };
      };
    };
    cms: {
      weapons: object[];
    };
  };
}

const WeaponsList: React.FC<Props> = ({ data }) => {
  const weapons = data.cms.weapons;

  const [keyName, setKName] = useContext(FilterSelectionContext);
  const [keyValue] = useContext(FilterSelectionValueContext);

  return (
    <>
      <TitleImage
        title="Arsenał"
        image={data.allDataJson.edges[0].node.arsenalPage.titleImage}
      />
      <Fade>
        <StyledContainer>
          <NavContainer>
            <StyledLink
              to={"/arsenal/"}
              onClick={() => setKName("")}
            >{`Arsenał \u2192`}</StyledLink>
            {keyValue ? (
              <StyledValue>{keyValue.toUpperCase()}</StyledValue>
            ) : null}
          </NavContainer>
          {keyValue ? (
            <KeyValueContainer>{keyValue.toUpperCase()}</KeyValueContainer>
          ) : null}

          <FlexWrapper>
            <CategoryList
              weapons={weapons}
              keyName={keyName}
              keyValue={keyValue}
            />
          </FlexWrapper>
        </StyledContainer>
        <SEO />
      </Fade>
    </>
  );
};

export default WeaponsList;

const StyledContainer = styled(Container)`
  position: relative;
  margin-bottom: ${(props) => props.theme.space[3]}px;
  margin-top: -${(props) => props.theme.space[4]}px;
`;

export const NavContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: ${(props) => props.theme.space[5]}px;
  margin-bottom: ${(props) => props.theme.space[6]}px;
`;

export const StyledLink = styled(Link)`
  color: ${(props) => props.theme.colors.primary};
  font-weight: bold;
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  margin-right: ${(props) => props.theme.space[2]}px;
  letter-spacing: 0.1rem;
`;

export const StyledValue = styled.div`
  font-size: ${(props) => props.theme.fontSizes[2]}px;
  color: ${(props) => props.theme.colors.greyLink};
  font-weight: bold;
  letter-spacing: 0.1rem;
`;

const KeyValueContainer = styled.div`
  font-size: ${(props) => props.theme.fontSizes[8]}px;
  margin-bottom: ${(props) => props.theme.space[7]}px;
`;

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${(props) => props.theme.space[3]}px;
`;

export const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          arsenalPage {
            titleImage {
              childImageSharp {
                fluid(quality: 85, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    cms {
      weapons(stage: PUBLISHED, orderBy: weaponId_ASC) {
        weaponId
        countries {
          name
        }
        category
        slug
        name
        image {
          handle
          width
          height
        }
        ammoType {
          name
        }
        gunPower
        cells1
        cells2
        characteristics
        description
        featuredGuns
        linksToWeapons
      }
    }
  }
`;
